<template>
  <div class="Search-style-component">
    <label v-if="label != null">{{ label }} </label>
    <div
      class="search-box"
      :class="[classToUse && classToUse != null ? serachLocale : '', className]"
    >
      <font-awesome-icon
        icon="search"
        class="icon"
        v-if="getSearchLoading === false"
      />
      <font-awesome-icon
        icon="circle-notch"
        class="icon icon-loader"
        fa-spin
        v-if="getSearchLoading"
        :pulse="true"
      />

      <input
        @input="debounceInput"
        v-model="searchNum"
        class="search-input"
        placeholder="Chercher"
      />
      <font-awesome-icon
        icon="times"
        class="icon-right"
        @click="resetSearchNumDossier"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      searchNum: this.value
    };
  },
  props: {
    value: { require: true },
    page: { require: false },
    perPage: { require: false },
    type: { require: false },
    semaine: { require: false },
    year: { require: false },
    getAnomalieData: { require: false },
    getValiditeData: { require: false },
    anomalie: { require: false },
    label: { default: null },
    classToUse: { required: false },
    className: { default: '' }
  },
  methods: {
    ...mapActions(['fastSearch']),
    debounceInput: _.debounce(function(e) {
      this.searchNum = e.target.value == '' ? null : e.target.value;
      this.$emit('changeSearchValue', this.searchNum);
    }, 500),
    resetSearchNumDossier() {
      this.searchNum = null;
      this.$emit('changeSearchValue', this.searchNum);
    }
  },
  computed: {
    ...mapGetters(['getSearchLoading'])
  }
};
</script>

<style scoped lang="scss">
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    background-color: #fdfbfb;
    padding: 5px;
    color: #a2a2a2;
    border: none;
    border-radius: 19px;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  .search-white-color {
    background-color: #fff;

    .search-input {
      background-color: #fff;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
</style>
